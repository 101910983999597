import React, { FC } from 'react';
import classNames from 'classnames';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { ITextBlockProps } from './model';
import './TextBlock.scss';

const TextBlock: FC<ITextBlockProps> = ({ text, className = '' }) => (
  <div
    className={classNames('text-block', {
      [`${className}`]: className,
    })}
  >
    <DangerouslySetInnerHtml html={text} />
  </div>
);

export default TextBlock;
